import { Vue } from 'vue-facing-decorator';
export default class CommonStyle extends Vue{
  public static SelectedItemHeadingColor = '#1E3A8A';
  public static SelectedItemDivColor = '#F5F8FF';
  public static UnSelectedItemDivColor = '#fff';
  public static UnSelectedItemHeadingColor = '#333333';
  public static UnSelectedItemSubHeadingColor = '#8B8B8B';
  public static lightOrDark(color: any) {
    // Check the format of the color, HEX or RGB?
    // declare var
    let r; let g; let b; let hsp;
    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +('0x' + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&',
      )
      );
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
    // eslint-disable-next-line prefer-const
    hsp = Math.sqrt(
      0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b),
    );
    if (hsp > 127.5) {
      return true;
    } else {
      return false;
    }
  }
}

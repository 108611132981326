import { Component, createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import mitt from 'mitt'; // Add
import i18n from './i18n';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
export const Store = store;
declare global {
  interface Window { $auth: any; GetVueComponentReference: any; $url: any }
}
declare module '@vue/runtime-core' {
  interface Vue {
    $EnvironmentBaseURL: string;
    $auth: string;
    $appInsight: typeof ApplicationInsights;
    $url: string;
  }
}
export const app = createApp(App as unknown as Component);
export const EnvironmentBaseURL = window.$url;
app.config.globalProperties.$EnvironmentBaseURL = EnvironmentBaseURL;
export const Root = mitt(); // Add
export const { t } = i18n.global;
app.config.globalProperties.emitter = Root;
app.config.globalProperties.$SignalRBaseURL =  process.env.VUE_APP_SIGNALRBASEURL;
app.config.globalProperties.$ApplicationInsightsKey = process.env.VUE_APP_APPLICATIONINSIGHTSKEY;
export const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: app.config.globalProperties.$ApplicationInsightsKey,
  connectionString: 'InstrumentationKey=' + app.config.globalProperties.$ApplicationInsightsKey,
  enableAutoRouteTracking: true,
} });
appInsights.loadAppInsights();
appInsights.trackPageView();
export const Authorization = window.$auth;
export const FetchData = async (url: string, body: any, apicontentType = 0) => {
  try {
    const path = EnvironmentBaseURL + 'AdminV2Api' + url;
    const contentType = 'application/json';
    const options = {
      method: 'POST',
      headers: {
        Authorization,
        'Access-Control-Allow-Origin': '*',
      },
      body,
    };
    if(apicontentType === 0 || apicontentType === 2) {
      options.headers = Object.assign(options.headers, {'Content-Type': contentType});
    }
    const response = await fetch(path, options);
    if (!response.ok) {
      throw new Error(
        'Api break',
      );
    }
    let data: Blob | '';
    if(apicontentType === 2 || apicontentType === 3) {
      data = await response.blob();
      return TransformResponse(data);
    } else {
      data = await response.json();
      return TransformResponse(JSON.stringify(data));
    }
    // const
  } catch (error) {
    // Some Error Occured
  }
};
const TransformResponse = (data: any)=> {
  if (typeof data === 'string') {
    try {
      const msAjaxRe = new RegExp(/^\/Date\((d|-|.*)\)[\/|\\]$/);
      const customParser = (key: string, value: string) => {
        /// Replaces MS .NET MVC FORMATTED DATES WITH JavaScript dates.
        const match = msAjaxRe.exec(value);
        if (match) {
          const b = match[1].split(/[-+,.]/);
          return new Date(b[0] ? +b[0] : 0 - +b[1]);
        }
        return value;
      };
      if (data.indexOf('HTTP_POTENTIALLY_DANGEROUS_REQUEST') > 0) {
        const error: SyntaxError = new SyntaxError('');
        HandleError(error);
      } else {
        data = JSON.parse(data, customParser);
      }
    } catch (error) {
      if (error instanceof SyntaxError && data.indexOf('HTTP_POTENTIALLY_DANGEROUS_REQUEST') > 0) {
        HandleError(error);
      }
    }
  }
  return data;
};
function HandleError(error: SyntaxError) {
  const errormsg = 'Request Validation has detected a potentially dangerous client input value, and processing of the request has been aborted';
  error.message = errormsg;
  alert(error.message);
  Promise.reject(error);
  appInsights.trackException({exception: error});
}
app.config.globalProperties.$appInsight = appInsights;
app.use(router);
app.use(i18n);
app.use(store);
app.mount('#app');

export class AppColorSettingsInfo {

  public static createEmpty(): AppColorSettingsInfo {
    return new AppColorSettingsInfo('', '');
  }
  constructor(
    public topNavForegroundColor: string= '',
    public topNavBackgroundColor: string= '',
  ) {}
}

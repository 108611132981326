const EmitEnum = {
  addNewNotification: 'add-new-notification',
  disableProgramItems: 'disable-program-items',
  enableProgramItems: 'enable-program-items',
  disableNotificationItems: 'disable-notification-items',
  enableNotificationItems: 'enable-notification-items',
  disableProgramSessionQuestionsItems: 'disable-program-session-question-items',
  enableProgramSessionQuestionsItems: 'enable-program-session-question-items',
  disableProgramEvaluationQuestionItems: 'disable-program-evaluation-question-items',
  enableProgramEvaluationQuestionItems: 'enable-program-evaluation-question-items',

  disableCustomCategoryItems: 'disable-CustomCategory-items',
  enableCustomCategoryItems: 'enable-CustomCategory-items',
  disableGroupSelectionItems: 'disable-group-selection-items',
  enableGroupSelectionItems: 'enable-group-selection-items',
};

export default Object.freeze(EmitEnum);

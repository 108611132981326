export class UserInformation {
  public static createEmpty(): UserInformation {
    return new UserInformation('', '', '' , '' , '', '', '', false, false);
  }
  constructor(
    public UserId: string = '',
    public Username: string = '',
    public AdminTypeForUpperRightCorner: string = '',
    public UserHash: string,
    public Firstname: string = '',
    public Lastname: string = '',
    public Email: string = '',
    public IsActiveDirectoryAdminUser: boolean = false,
    public IsOAuthAdminUser: boolean = false,
    public IsApplicationAdmin: boolean = false,
    public IsDepartmentAdmin: boolean = false,
    public IsEventAdmin: boolean = false,
    public IsSuperAdmin: boolean = false,
    public DepartmentId: number = 0,
  ) {}
}

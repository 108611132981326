/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import EmitEnum from '@/enums/EmitEnums';
import { SignalRModelRequest } from './SignalRModelRequest';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { ModuleEnumParse } from '@/enums/ModuleEnum';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';
import SubModuleEnum, { SubModuleEnumParse } from '@/enums/SubModuleEnum';
import { FetchData, Root } from '@/main';
export class BaseSignalRModel {
  // Create New GUID
  public static newGuid() {
    const guidFormatter = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return guidFormatter.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  public arrSignalRModelRequests: SignalRModelRequest[] = [];

  constructor(public rootObj: any,  public $store: any) {

  }
  // Send Request to Signal R Hub that an operation is being performed
  public SendSignalRModelRequestToHub(signalRModelRequest: SignalRModelRequest, url: string) {
    FetchData(url, JSON.stringify(signalRModelRequest))
      .then((response) => {
        this.EmitAddNewNotifications(signalRModelRequest);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      }).finally(() => {
        // do nothing
      });

  }

  // Send Request to Signal R Hub that an operation is being performed
  public SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest: SignalRModelRequest, url: string) {
    FetchData(url, JSON.stringify(signalRModelRequest))
      .then((response) => {
        // No Need to emit as already added to notification popup.
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      }).finally(() => {
        // Do nothing
      });

  }

  // Send Request to Signal R Hub that an operation is being performed
  public SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest: SignalRModelRequest, url: string) {
    FetchData(url, JSON.stringify(signalRModelRequest))
      .then((response) => {
        // No Need to emit as already added to notification popup.
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      }).finally(() => {
        // Do nothing
      });

  }
  // Method that fires when Response is received from SignalR
  public SignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.EmitAddNewNotifications(signalRModelRequest);
  }
  // Get Signal R Model Request By ID
  public GetSelectedSignalRModelRequestByID(id: number): SignalRModelRequest {
    const objIndex = this.arrSignalRModelRequests.findIndex(
      (obj: SignalRModelRequest) => obj.Id === id,
    );
    const signalRModelRequest: SignalRModelRequest = this.arrSignalRModelRequests[objIndex];
    return signalRModelRequest;
  }
  // Emit notification object to Notification popup
  public EmitAddNewNotifications(notification: SignalRModelRequest) {
    Root.emit(EmitEnum.addNewNotification, notification);
  }

  public IsLoggedInUserIsMe(signalRModelRequest: SignalRModelRequest): boolean {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    return signalRModelRequest.UserId === reqInfo.Username;
  }

  public ShowNotificationsPopup() {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
  }

  public GetInitialNotifications(count: number) {
    const reqInfo = this.GetRequestInfoFromStore();
    let notifications: SignalRModelRequest[] = [];
    FetchData('/SignalRNotification/GetNotificationLogs', JSON.stringify({
      recordsCount: count,
      applicationId: reqInfo.ApplicationId,
      eventId: reqInfo.ApplicationInstanceId,
      userId: reqInfo.Username,
    }))
      .then((response) => {
        if (response.success) {
          notifications = response.logs;
          if (notifications.length > 0) {
            Root.emit('emit-initial-notifications', notifications);
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      }).finally(() => {
        // Do nothing
      });
  }
  // Get UserName to show in notifications
  public GetUserName(notification: SignalRModelRequest, translatedText: string): string {
    const reqInfo = this.GetRequestInfoFromStore();
    let name = '';
    if (notification.UserId === reqInfo.Username) {
      name = translatedText;
    } else {
      name = notification.UserName;
    }
    return name;
  }

  public GetSeletedItemName(notification: SignalRModelRequest): string {
    let itemName = '';

    if (notification.SubModule !== SubModuleEnum.Delete && notification.SubModule !== SubModuleEnum.DialogueDeleteAllResponse) {
      const eItem: any = notification.JSONData === '' ? null : JSON.parse(this.jsonEscape(notification.JSONData));
      if (eItem !== null) {
        if (eItem.selectedName) {
          itemName = eItem.selectedName;
          if (itemName.length > 15) {
            itemName = itemName.substring(0, 15) + '...';
          }
          itemName = itemName === '' ? '' : '(' + itemName + ')';
        }
      }
    }

    return itemName;
  }

  public GetSignalRModelRequestFromLogs(notifications: any): SignalRModelRequest[] {
    const arrSignalRModelRequests: SignalRModelRequest[] = [];
    let i = 1;
    notifications.forEach(function(item: any) {

      arrSignalRModelRequests.push(new SignalRModelRequest(i, ModuleEnumParse.GetModuleEnum(String(item.module)), SubModuleEnumParse.GetSubModuleEnum(String(item.subModule)), Number(item.applicationId), Number(item.eventId), String(item.guid), String(item.userId), String(item.userName), String(item.adminType), '', [], '', String(item.description), '', String(item.description), Boolean(item.isSuccess), null,'', false, new Date(item.resultDateTime + 'Z'), String(item.jsonData), NotificationStatusEnum.Received, SignalRModelRequest.ToRelativeDate(new Date(item.resultDateTime + 'Z'))));
      i++;
    });
    return arrSignalRModelRequests;
  }

  // Read Data from Vuex-store
  protected GetRequestInfoFromStore(): AppRequestInfo {
    const storeHelper: StoreHelper = new StoreHelper(this.$store);
    return storeHelper.GetRequestInfoFromStore();
  }

  private jsonEscape(str: string)  {
    return str.replace(/\n/g, '\\\\n').replace(/\r/g, '\\\\r').replace(/\t/g, '\\\\t');
  }
}

import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { LatestNewsRequestInfo } from '@/entities/Application/LatestNewsRequestInfo';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import { LicenseApplicationRequestInfo } from '@/entities/Application/LicenseApplicationRequestInfo';
import { ConsumptionBasedProductPrice } from '@/entities/ConsumptionBasedProductPrice';

export class StoreHelper {
  constructor(public $store: any) {

  }
  public SaveValueToStore(reqInfo: AppRequestInfo) {
    this.$store.commit('setRequestInfo', reqInfo);
  }
  // Save latest news information into store
  public SaveLatestNewsToStore(latestNews: LatestNewsRequestInfo) {
    this.$store.commit('setLatestNews', latestNews);
  }
  // Save latest news information into store
  public SaveLicenseApplicationToStore(licenseApplication: LicenseApplicationRequestInfo) {
    this.$store.commit('setLicenseApplication', licenseApplication);
  }
  public SaveBrowserSettingsToStore(settings: BrowserSettingsInfo) {
    this.$store.commit('setBrowserSettings', settings);
  }

  public SaveAppColorSettingsToStore(settings: AppColorSettingsInfo) {
    this.$store.commit('setApplicationColorSettings', settings);
  }

  public SaveActiveEventStartDate(startDate: Date) {
    this.$store.commit('setActiveEventStartDate', startDate);
  }
  public SaveActiveEventIanaTimeZone(timeZone: string) {
    this.$store.commit('setActiveEventIanaTimeZone', timeZone);
  }

  public SaveEnableProgramQuestionValue(enable: boolean) {
    this.$store.commit('setEnableProgramQuestionValue', enable);
  }
  public SaveEnableProgram(enable: boolean) {
    this.$store.commit('setEnableProgram', enable);
  }
  public SaveEnableInformation(enable: boolean) {
    this.$store.commit('setEnableInformation', enable);
  }
  public SaveEventNameToStore(name: string) {
    this.$store.commit('setActiveEventname', name);
  }
  public SaveConsumptionBasedProductPricingToStore(pricingObject: ConsumptionBasedProductPrice) {
    this.$store.commit('setConsumptionBasedProductPricingValues', pricingObject);
  }
  public GetRequestInfoFromStore(): AppRequestInfo {
    return this.$store.getters.requestInfo;
  }

  public GetActiveEventStartDateFromStore(): Date {
    return this.$store.getters.activeEventStartDate;
  }
  public GetActiveEventIanaTimeZoneFromStore(): string {
    return this.$store.getters.activeEventIanaTimeZone;
  }

  public GetEnableProgramQuestionValueFromStore(): boolean {
    return this.$store.getters.enableProgramQuestionValue;
  }
  public GetEnableProgramFromStore(): boolean {
    return this.$store.getters.enableProgram;
  }
  public GetEnableInformationFromStore(): boolean {
    return this.$store.getters.enableInformation;
  }
  // Get latest news from store
  public GetLatestNewsFromStore(): LatestNewsRequestInfo {
    return this.$store.getters.latestNewsInfo;
  }
  // Get Browser settings from store
  public GetBrowserSettingsFromStore(): BrowserSettingsInfo {
    return this.$store.getters.browserSettings;
  }
  public GetApplicationColorSettingsFromStore(): AppColorSettingsInfo {
    return this.$store.getters.appcolorSettings;
  }
  // Get license information from store
  public GetLicenseApplicationFromStore(): LicenseApplicationRequestInfo {
    return this.$store.getters.licenseApplicationInfo;
  }
  // get Active Event Name
  public GetActiveEventNameFromStore(): string {
    return this.$store.getters.activeEventName;
  }
  public GetconsumptionBasedProductPricingFromStore(): ConsumptionBasedProductPrice {
    return this.$store.getters.consumptionBasedProductPricing;
  }
  // Save the enable chat and discussion value into store
  public SaveEnableMessagingfeature(enable: any) {
    this.$store.commit('setEnableMessagingfeature', enable);
  }

  // Get the enable chat and discussion value from store
  public GetEnableMessagingfeatureFromStore(): boolean {
    return this.$store.getters.enableMessagingfeature;
  }
}

import { NavBarImageType } from './NavBarImageType';

export class NavBarImages {
  public NavBarImage: NavBarImageType[] = [];
  constructor() {
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/settings-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/settings.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/live-streaming-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/live-streaming.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/ventla-invite-icon-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ventla-invite-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/webpage-icon-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/webpage-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/program-icon-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages//program-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/information-icon-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages//information-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/dialogue-icon-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/dialogue-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/settings-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages//social-media-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/participants-icon-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/participants-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/settings-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/image-gallery-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/settings-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages//activity-feed-icon.svg')});
    this.NavBarImage.push({ActiveImageSrc:require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ActiveIcons/settings-active.svg'),ImageSrc: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/gamification-icon.svg')});
  }
}

export class LicenseApplicationRequestInfo {
  public static createEmpty(): LicenseApplicationRequestInfo {
    return new LicenseApplicationRequestInfo(false, null, null, null, null, null, null, '', '', 0, 0, '', 0);
  }
  constructor(
    public isApplicationLegacyModel: boolean,
    public ApplicationActiveLicense: any,
    public ApplicationLicenseItems: any,
    public ApplicationAddOns: any,
    public applicationPremiumFeaturesReadOnly: any,
    public GroupedFeaturesList: any,
    public GroupedFeaturesListAddOns: any,
    public Name: string,
    public CreateAccountType: string,
    public ConsumedAdmins: number,
    public ConsumedEvents: number,
    public IconUrl: string,
    public AdditionalParticipantSeats: number,
  ) {

  }
}

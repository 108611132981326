/* eslint-disable max-len */
import { ApplicationItem } from '@/entities/Application/Application';
import { EventItem } from '@/entities/ApplicationInstance/EventItem';
import { UserInformation } from '@/entities/UserInformation';
export class ApplicationViewModel {
  public static empty() {
    return new ApplicationViewModel(ApplicationItem.createEmpty(), [],
      UserInformation.createEmpty(), '','');
  }
  public ActiveEvent: EventItem = EventItem.createEmpty();
  public ActiveEvents: EventItem[] = [];
  public ApplicationColorWithBackGroundColor = '';
  constructor(
    public ApplicationInformation: ApplicationItem,
    public Events: EventItem[],
    public LoggedInUserInformation: UserInformation,
    public environmentBaseURL: string,
    public MuxEnvironmentKey: string) {
    for (const event of Events) {
      event.RedirectUrl = environmentBaseURL + '/Admin/Auth/ChangeApplicationInstanceAndNavigate/' + event.Id;
      if (event.ListLogoUrl.toLowerCase() === '/content/images/imagemissing.png') {
        event.ListLogoUrl = environmentBaseURL + event.ListLogoUrl;
      }
      if (event.IsActive) {
        this.ActiveEvent = event;
        if (this.ActiveEvent.ListLogoUrl.toLowerCase() === '/content/images/imagemissing.png') {
          this.ActiveEvent.ListLogoUrl = this.environmentBaseURL + this.ActiveEvent.ListLogoUrl;
        }
      }
      if (!event.IsArchived) {
        this.ActiveEvents.push(event);
      }
    }
    this.ApplicationColorWithBackGroundColor = '{color:' + ApplicationInformation.TopNavForegroundColor + ',backgroundColor:' + ApplicationInformation.TopNavBackgroundColor + '}';
  }
}

import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';

export class ProgramTrackItem {
  public static createEmpty(): ProgramTrackItem {
    return new ProgramTrackItem(0, '');
  }

  // eslint-disable-next-line max-len
  public static GetTrackItemFromTrackItemSignalR(tItem: ProgramTrackItem, track: ProgramTrackItemSignalR): ProgramTrackItem {
    tItem.Id = track.Id;
    tItem.Name = track.Name;
    tItem.IconUrl = track.IconUrl;
    tItem.IconUrlOriginal = track.IconUrlOriginal;
    tItem.TrackColor  = track.TrackColor;
    return tItem;
  }
  constructor(
    public Id: number,
    public Name: string,
    public IconUrl: string = '',
    public IconUrlOriginal: string = '',
    public TrackColor: string = '',
    // to retain values for cancel operation
    public OldIconUrl?: string,
    public OldIconUrlOriginal?: string,
    public OldName?: string,
    public IsSelected?: boolean,
    public OldTrackColor?: string,
  ) { }
}
export class ProgramTrackItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): ProgramTrackItemSignalR {
    return new ProgramTrackItemSignalR();
  }

  public static GetProgramTracksItemSignalR(track: ProgramTrackItem): ProgramTrackItemSignalR {
    const item: ProgramTrackItemSignalR = ProgramTrackItemSignalR.createEmpty();
    item.Id = track.Id;
    item.Name = track.Name;
    item.IconUrl = track.IconUrl;
    item.IconUrlOriginal = track.IconUrlOriginal;
    item.selectedName = track.Name;
    item.selectedCount = 1;
    item.TrackColor = track.TrackColor;
    return item;
  }

  constructor(
    public Id: number = 0,
    public Name: string = '',
    public IconUrl: string = '',
    public IconUrlOriginal: string = '',
    public TrackColor: string = '',
  ) {
    super(0, '');
  }
}


import { ProductPrice } from '../entities/ProductPrice';

export class ConsumptionBasedProductPrice {
  public static createEmpty(): ConsumptionBasedProductPrice {
    return new ConsumptionBasedProductPrice();
  }
  constructor(
    public MeetAppLiveProductPrice?: ProductPrice,
    public PreRecordedVideoProductPrice?: ProductPrice,
    public PreRecordedVideoStorageProductPrice?: ProductPrice,
    public RTMPLiveProductPrice?: ProductPrice,
  ) { }
}

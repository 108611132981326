import { MapSupportingItem } from '@/entities/MapSupportingItem';
import { VirtualMeetingsSupportingItem } from '@/entities/VirtualMeetingsSupportingItem';
import { VonageSessionData } from '../VonageSessionData';
import { ProgramTrackItem } from '@/entities/Program/ProgramTrackItem';
import { ProgramTagItem } from './ProgramTagItem';
import { ProgramDurationItem } from './ProgramDurationItem';
import { ProgramSessionQuestionsItem } from './ProgramSessionQuestionsItem';
import { CustomCategoryItem } from '../CustomCategory/CustomCategoryItem';
import { MultimediaItem } from './MultimediaItem';
import { ProgramEvaluationQuestionsItem } from './ProgramEvaluationQuestionsItem';
import { CustomCategoryFolder } from '../CustomCategory/CustomCategoryFolder';
import Common from '@/helper/Common';
import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';
import { VodDetail } from '../VodDetail';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import CommonStyle from '@/helper/CommonStyle';


export class ProgramItem implements MapSupportingItem, VirtualMeetingsSupportingItem {
  public static createEmpty(): ProgramItem {
    return new ProgramItem(0, '', '');
  }

  // eslint-disable-next-line max-len
  public static GetProgramItemFromProgramItemSignalR(pItem: ProgramItem, programSignalRItem: ProgramItemSignalR): ProgramItem {
    pItem.Id = programSignalRItem.Id;
    pItem.Title = programSignalRItem.Title;
    pItem.Description = programSignalRItem.Description;
    pItem.ImageUrl = programSignalRItem.ImageUrl;
    pItem.Track = programSignalRItem.Track;
    pItem.ProgramTags = programSignalRItem.ProgramTags;
    pItem.StartDate = new Date(programSignalRItem.StartDate);
    pItem.EndDate = new Date(programSignalRItem.EndDate);
    pItem.Duration = programSignalRItem.Duration;
    pItem.Location = programSignalRItem.Location;
    pItem.Latitude = programSignalRItem.Latitude;
    pItem.Longitude = programSignalRItem.Longitude;
    pItem.Address = programSignalRItem.Address;
    pItem.City = programSignalRItem.City;
    pItem.MainMediaFullUrl = programSignalRItem.MainMediaFullUrl;
    pItem.FeaturedImageUrl = programSignalRItem.FeaturedImageUrl;
    pItem.FeaturedOrginalImageUrl = programSignalRItem.FeaturedOrginalImageUrl;
    pItem.FeaturedImageHeight = programSignalRItem.FeaturedImageHeight;
    pItem.FeaturedImageWidth = programSignalRItem.FeaturedImageWidth;
    pItem.TopPosition = programSignalRItem.TopPosition;
    pItem.LeftPosition = programSignalRItem.LeftPosition;
    pItem.ShowFeatureImageBelowTitle = programSignalRItem.ShowFeatureImageBelowTitle;
    pItem.VirtualMeetingCode = programSignalRItem.VirtualMeetingCode;
    pItem.VirtualMeetingLink = programSignalRItem.VirtualMeetingLink;
    pItem.IsRecordedSession = programSignalRItem.IsRecordedSession;
    pItem.IsRecordedAutomatically = programSignalRItem.IsRecordedAutomatically;
    pItem.VirtualMeetingType = programSignalRItem.VirtualMeetingType;
    pItem.VirtualMeetingUrl = programSignalRItem.VirtualMeetingUrl;
    pItem.VirtualMeetingLiveStreamId = programSignalRItem.VirtualMeetingLiveStreamId;
    pItem.HideEndTimeDuration = programSignalRItem.HideEndTimeDuration;
    pItem.VirtualMeetingParentType = programSignalRItem.VirtualMeetingParentType;
    pItem.Timeoffset = programSignalRItem.Timeoffset;
    pItem.EndDateUTC = programSignalRItem.EndDateUTC;
    pItem.StartDateUTC = programSignalRItem.StartDateUTC;
    pItem.HasExternalZoomLink = programSignalRItem.HasExternalZoomLink;
    pItem.ParticipantGroups  = programSignalRItem.ParticipantGroups;
    pItem.ParticipantGroupIds = programSignalRItem.ParticipantGroupIds;
    pItem.HideFromEventWebpage = programSignalRItem.HideFromEventWebpage;
    pItem.IsSessionCheckInEnabled = programSignalRItem.IsSessionCheckInEnabled;
    pItem.DisplayGroupInfoInApp = programSignalRItem.DisplayGroupInfoInApp;
    pItem.MaxCheckInLimitQuantity = programSignalRItem.MaxCheckInLimitQuantity;
    pItem.CheckedInParticipantsCount = programSignalRItem.CheckedInParticipantsCount;
    pItem.IsSessionChatEnabled = programSignalRItem.IsSessionChatEnabled;
    return pItem;
  }
  constructor(
    public Id: number,
    public Title: string,
    public Description: string,
    public ApplicationId: number = 0,
    public ApplicationInstanceId: number = 0,
    public ImageUrl: string = '',
    public MainMediaFullUrl: string = '',
    public Location: string = '',
    public Address: string = '',
    public City: string = '',
    public Latitude: number = 0,
    public Longitude: number = 0,
    public StartDate: Date = new Date(),
    public EndDate: Date = new Date(),
    public Track?: ProgramTrackItem | null,
    public ProgramTags?: ProgramTagItem[] | [],
    public Duration: ProgramDurationItem | null = ProgramDurationItem.createEmpty(),
    public FeaturedOrginalImageUrl: string = '',
    public FeaturedImageUrl: string = '',
    public SessionQuestions?: ProgramSessionQuestionsItem[] | [],
    public LinkedInformation?: CustomCategoryItem[] | [],
    public CustomCategoryFolders?: CustomCategoryFolder[] | [],
    public ProgramMedias?: MultimediaItem[] | MultimediaItem[],
    public ProgramMedias360?: MultimediaItem[] | MultimediaItem[],
    public EvaluationQuestions?: ProgramEvaluationQuestionsItem[] | [],
    public ProgramQuestionPublishedLink: string = '',
    public ProgramQuestionLinkExpirationDate: Date = new Date(),
    public styleProgramDiv: string = '#fff',
    public styleProgramDescriptionText: string = '#000',
    public styleProgramTimeText: string = '#8B8B8B',
    public modifedDate: Date = new Date(),
    public DisableItem: boolean = false,
    public FeaturedImageWidth: number = 320, // default featured image width
    public FeaturedImageHeight: number = 200, // default featured image height
    public TopPosition: string = '0',
    public LeftPosition: string = '0',
    public ShowFeatureImageBelowTitle: boolean = false,
    public HideEndTimeDuration: boolean = false,
    public IsLinked: boolean= false,
    public VirtualMeetingLink: string = '',
    public VirtualMeetingType: string = '',
    public VirtualMeetingCode: string = '',
    public VirtualMeetingUrl: string = '',
    public HasExternalZoomLink: boolean = false,
    public VirtualMeetingLiveStreamId: number = 0,
    public VirtualMeetingParentType: string = '',
    public Timeoffset: number = 0,
    public EndDateUTC: Date = new Date(),
    public StartDateUTC: Date = new Date(),
    public IsRecordedSession: boolean = false,
    public IsRecordedAutomatically: boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VonageSessionData?: VonageSessionData|null,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VodDetail?: VodDetail|null,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public ParticipantGroups: ParticipantGroups[] = [],
    public ParticipantGroupIds: number[] = [],
    public HideFromEventWebpage: boolean = false,
    public IsSessionCheckInEnabled: boolean = false,
    public DisplayGroupInfoInApp: boolean = false,
    public MaxCheckInLimitQuantity: number = -1,
    public CheckedInParticipantsCount: number = 0,
    public IsSessionChatEnabled: boolean = false, // IsSessionChatEnabled
  ) {

  }
  public SetProgramObject(val: ProgramItem): any {
    return {
      Title: val.Title,
      Description: val.Description,
      ImageUrl: val.ImageUrl,
      MainMediaFullUrl: val.MainMediaFullUrl,
      Location: val.Location,
      Address: val.Address,
      City: val.City,
      Latitude: val.Latitude,
      Longitude: val.Longitude,
      StartDate: new Date(val.StartDate).setMilliseconds(0),
      ProgramTrackItem: val.Track == null ? ProgramTrackItem.createEmpty() : val.Track,
      ProgramTags: val.ProgramTags,
      ProgramDurationItem: val.Duration == null ? new ProgramDurationItem(1, 0) : val.Duration,
      FeaturedOrginalImageUrl: val.FeaturedOrginalImageUrl,
      FeaturedImageUrl: val.FeaturedImageUrl,
      TopPosition: val.TopPosition,
      LeftPosition: val.LeftPosition,
      ShowFeatureImageBelowTitle: val.ShowFeatureImageBelowTitle,
      VirtualMeetingLink: val.VirtualMeetingLink,
      IsRecordedSession: val.IsRecordedSession,
      IsRecordedAutomatically: val.IsRecordedAutomatically,
      VirtualMeetingType: val.VirtualMeetingType,
      VirtualMeetingCode: val.VirtualMeetingCode,
      VirtualMeetingUrl: val.VirtualMeetingUrl,
      VirtualMeetingLiveStreamId: val.VirtualMeetingLiveStreamId,
      VirtualMeetingParentType: val.VirtualMeetingParentType,
      HideEndTimeDuration: val.HideEndTimeDuration,
      Timeoffset: val.Timeoffset,
      HasExternalZoomLink: val.HasExternalZoomLink,
      ParticipantGroups: val.ParticipantGroups,
      ParticipantGroupIds: val.ParticipantGroupIds,
    };
  }

  public CompareProgramObjects({ val1, val2 }: { val1: ProgramItem; val2: ProgramItem }): boolean {
    const programItem1 = ProgramItem.prototype.SetProgramObject(val1);
    const programItem2 = ProgramItem.prototype.SetProgramObject(val2);
    programItem1.Description = programItem1.Description.trim().replace(/\s/g, '').replace(/&nbsp;/g, '');
    programItem2.Description = programItem2.Description.trim().replace(/\s/g, '').replace(/&nbsp;/g, '');
    programItem1.Description = this.StripHtml(String(programItem1.Description));
    programItem2.Description = this.StripHtml(String(programItem2.Description));
    if (programItem2.ParticipantGroupIds == null && programItem1.ParticipantGroupIds == null ) {
      // Do nothing
    } else {
      if (programItem2.ParticipantGroupIds.length === programItem1.ParticipantGroups.length) {
        programItem1.ParticipantGroupIds = [];
        programItem2.ParticipantGroupIds = [];
        programItem1.ParticipantGroups = [];
        programItem2.ParticipantGroups = [];
      } else if(programItem2.ParticipantGroupIds.length === 0 || programItem1.ParticipantGroups.length === 0) {
        programItem1.ParticipantGroupIds = [];
        programItem2.ParticipantGroupIds = [];
        programItem1.ParticipantGroups = [];
        programItem2.ParticipantGroups = [];
      }
    }
    if (Common.prototype.CompareObjects(programItem1, programItem2)) {
      return true;
    } else {
      return false;
    }
  }
  public StripHtml(html: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
export class ProgramItemResult {
  constructor(public ValidationErrors: string[], public Program: ProgramItem) { }
}

export class ProgramItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): ProgramItemSignalR {
    return new ProgramItemSignalR();
  }
  public static GetProgramSignalRItem(programItem: ProgramItem): ProgramItemSignalR {
    const item: ProgramItemSignalR = ProgramItemSignalR.createEmpty();
    item.Id = programItem.Id;
    item.Title = programItem.Title;
    item.Description = programItem.Description;
    item.ImageUrl = programItem.ImageUrl;
    item.Track = programItem.Track;
    item.ProgramTags = programItem.ProgramTags;
    item.StartDate = programItem.StartDate;
    item.EndDate = programItem.EndDate;
    item.Duration = programItem.Duration;
    item.Location = programItem.Location;
    item.Latitude = programItem.Latitude;
    item.Longitude = programItem.Longitude;
    item.Address = programItem.Address;
    item.City = programItem.City;
    item.MainMediaFullUrl = programItem.MainMediaFullUrl;
    item.FeaturedImageUrl = programItem.FeaturedImageUrl;
    item.FeaturedOrginalImageUrl = programItem.FeaturedOrginalImageUrl;
    item.FeaturedImageWidth = programItem.FeaturedImageWidth;
    item.FeaturedImageHeight = programItem.FeaturedImageHeight;
    item.TopPosition = programItem.TopPosition;
    item.LeftPosition = programItem.LeftPosition;
    item.selectedName = programItem.Title;
    item.selectedCount = 1;
    item.ShowFeatureImageBelowTitle = programItem.ShowFeatureImageBelowTitle;
    item.VirtualMeetingCode = programItem.VirtualMeetingCode;
    item.VirtualMeetingType = programItem.VirtualMeetingType;
    item.VirtualMeetingLink = programItem.VirtualMeetingLink;
    item.IsRecordedSession = programItem.IsRecordedSession;
    item.IsRecordedAutomatically = programItem.IsRecordedAutomatically;
    item.VirtualMeetingUrl = programItem.VirtualMeetingUrl;
    item.VirtualMeetingLiveStreamId = programItem.VirtualMeetingLiveStreamId;
    item.VirtualMeetingParentType = programItem.VirtualMeetingParentType;
    item.HideEndTimeDuration = programItem.HideEndTimeDuration;
    item.Timeoffset = programItem.Timeoffset;
    item.EndDateUTC = programItem.EndDateUTC;
    item.StartDateUTC = programItem.StartDateUTC;
    item.HasExternalZoomLink = programItem.HasExternalZoomLink;
    item.ParticipantGroups = programItem.ParticipantGroups;
    item.ParticipantGroupIds = programItem.ParticipantGroupIds;
    item.HideFromEventWebpage = programItem.HideFromEventWebpage;
    item.IsSessionCheckInEnabled = programItem.IsSessionCheckInEnabled;
    item.DisplayGroupInfoInApp = programItem.DisplayGroupInfoInApp;
    item.MaxCheckInLimitQuantity = programItem.MaxCheckInLimitQuantity;
    item.CheckedInParticipantsCount = programItem.CheckedInParticipantsCount;
    item.IsSessionChatEnabled = programItem.IsSessionChatEnabled;
    return item;
  }
  constructor(
    public Id: number = 0,
    public Title: string = '', public Description: string = '',
    public ImageUrl: string = '',
    public MainMediaFullUrl: string = '',
    public Location: string = '',
    public Address: string = '', public City: string = '',
    public Latitude: number = 0, public Longitude: number = 0,
    public StartDate: Date = new Date(),
    public EndDate: Date = new Date(),
    public Track?: ProgramTrackItem | null,
    public ProgramTags?: ProgramTagItem[] | [],
    public Duration: ProgramDurationItem | null = ProgramDurationItem.createEmpty(),
    public FeaturedOrginalImageUrl: string = '',
    public FeaturedImageUrl: string = '',
    public FeaturedImageWidth: number = 320, // default featured image width
    public FeaturedImageHeight: number = 200, // default featured image height
    public TopPosition: string = '0',
    public LeftPosition: string = '0',
    public styleProgramDiv: string = '#f9f9f9',
    public styleProgramDivTitle: string =CommonStyle.SelectedItemHeadingColor,
    public DisableItem: boolean = false,
    public ShowFeatureImageBelowTitle: boolean = false,
    public HideEndTimeDuration: boolean = false,
    public VirtualMeetingLink: string = '',
    public VirtualMeetingType: string = '',
    public VirtualMeetingCode: string = '',
    public VirtualMeetingUrl: string = '',
    public VirtualMeetingParentType: string = '',
    public VirtualMeetingLiveStreamId: number = 0,
    public HasExternalZoomLink: boolean = false,
    public Timeoffset: number = 0,
    public EndDateUTC: Date = new Date(),
    public StartDateUTC: Date = new Date(),
    public IsRecordedSession: boolean = false,
    public IsRecordedAutomatically: boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public ParticipantGroups: ParticipantGroups[] = [],
    public ParticipantGroupIds: number[] = [],
    public HideFromEventWebpage: boolean = false,
    public DisplayGroupInfoInApp: boolean = false,
    public IsSessionCheckInEnabled: boolean = false,
    public MaxCheckInLimitQuantity: number = -1,
    public CheckedInParticipantsCount: number = 0,
    public IsSessionChatEnabled: boolean = false,
  ) {
    super(0, '');
  }

}

export class ProgramItemDetailSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): ProgramItemDetailSignalR {
    return new ProgramItemDetailSignalR();
  }
  public static GetProgramDetailSignalRItem(programDetailItem: any): ProgramItemDetailSignalR {
    const item: ProgramItemDetailSignalR = ProgramItemDetailSignalR.createEmpty();
    item.selectedName = programDetailItem.Title;
    item.selectedCount = 1;
    item.dataItem = programDetailItem.dataItem;
    item.id = programDetailItem.id;
    item.programDetailType = programDetailItem.programDetailType;
    return item;
  }
  constructor(
    public dataItem: any = [],
    public id: number = 0,
    public programDetailType: string = '',
  ) {
    super(0, '');
  }
}

export class LatestNewsRequestInfo {
  public static createEmpty(): LatestNewsRequestInfo {
    return new LatestNewsRequestInfo(false, '', '', '');
  }
  constructor(
    public IsPublished: boolean = false,
    public Title: string = '',
    public Description: string = '',
    public ImageUrl: string = '',
  ) { }
}

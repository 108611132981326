
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import { ConsumptionBasedProductPrice } from '@/entities/ConsumptionBasedProductPrice';
import {createStore} from 'vuex';

const store = createStore({
  state: {
    requestInfo: AppRequestInfo.createEmpty(),
    activeEventStartDate: new Date(),
    activeEventIanaTimeZone: '',
    enableProgramQuestionValue: false,
    enableProgram: false,
    enableInformation: false,
    latestNewsInfo: null,
    licenseApplicationInfo: null,
    browserSettings: BrowserSettingsInfo.createEmpty(),
    appcolorSettings: AppColorSettingsInfo.createEmpty(),
    activeEventName: '',
    consumptionBasedProductPrice: ConsumptionBasedProductPrice.createEmpty(),
    enableMessagingfeature: false,
  },
  mutations: {
    setRequestInfo(state, info) {
      state.requestInfo = info;
    },
    setActiveEventStartDate(state, info) {
      state.activeEventStartDate = info;
    },
    setActiveEventIanaTimeZone(state, info) {
      state.activeEventIanaTimeZone = info;
    },
    setEnableProgramQuestionValue(state, info) {
      state.enableProgramQuestionValue = info;
    },
    setEnableProgram(state, info) {
      state.enableProgram = info;
    },
    setEnableInformation(state, info) {
      state.enableInformation = info;
    },
    setLatestNews(state, info) {
      state.latestNewsInfo = info;
    },
    setLicenseApplication(state, info) {
      state.licenseApplicationInfo = info;
    },
    setBrowserSettings(state, info) {
      state.browserSettings = info;
    },
    setApplicationColorSettings(state, info) {
      state.appcolorSettings = info;
    },
    setActiveEventname(state, info) {
      state.activeEventName = info;
    },
    setConsumptionBasedProductPricingValues(state, info) {
      state.consumptionBasedProductPrice = info;
    },
    setEnableMessagingfeature(state, info) {
      state.enableMessagingfeature = info;
    },
  },
  getters: {
    requestInfo: (state) => state.requestInfo,
    activeEventStartDate: (state) => state.activeEventStartDate,
    enableProgramQuestionValue: (state) => state.enableProgramQuestionValue,
    enableProgram: (state) => state.enableProgram,
    enableInformation: (state) => state.enableInformation,
    latestNewsInfo: (state) => state.latestNewsInfo,
    browserSettings: (state) => state.browserSettings,
    appcolorSettings: (state) => state.appcolorSettings,
    licenseApplicationInfo: (state) => state.licenseApplicationInfo,
    activeEventIanaTimeZone: (state) => state.activeEventIanaTimeZone,
    activeEventName: (state) => state.activeEventName,
    consumptionBasedProductPricing: (state) => state.consumptionBasedProductPrice,
    enableMessagingfeature: (state) => state.enableMessagingfeature,
  },
});
export default store;


import { Root } from '@/main';
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator';
@Component
class Loading extends Vue {
  @Prop()
  private showLoading = false;
  @Prop()
  private borderColor = '';
  @Prop()
  private loaderOpacity?: number;
  private loadingTextColor = '#bbb';
  private loadingImage: string = require('@/assets/Images/ventla-loader.gif');
  private GetColor() {
    return {
      'border-top-color': this.borderColor,
      'border-bottom-color': this.borderColor,
    };
  }
  private mounted() {
    Root.emit('hide-parent-loader', true);
  }
  private GetOpacity() {
    if (this.loaderOpacity != null) {
      const loadingOpacity = this.loaderOpacity;
      return { opacity: loadingOpacity };
    } else {
      return { opacity: 0.9 };
    }
  }
}
export default toNative(Loading);

export class EventItem {

  public static createEmpty(): EventItem {
    return new EventItem(0, '', '');
  }
  public static createHeader(Id: number, Name: string, ListLogoUrl: string, RedirectUrl: string): EventItem {
    return new EventItem(Id, Name, ListLogoUrl, RedirectUrl);
  }
  constructor(
    public Id: number,
    public Name: string = '',
    public ListLogoUrl: string = '',
    public RedirectUrl: string = '',
    public IsActive: boolean = true,
    public EnableNewsFeeds: boolean = true,
    public EnableParticipantList: boolean = true,
    public EnableRegistrationSystem: boolean = true,
    public EnableProgram: boolean = true,
    public EnableInformation: boolean = true,
    public EnableDialogue: boolean = true,
    public EnableImageArchive: boolean = true,
    public EnableGamification: boolean = true,
    public EnableActivityFeeds: boolean = true,
    public EnableLiveStreaming: boolean = false,
    public StartDate: Date = new Date(),
    public IsLinked: boolean = false,
    public IanaTimeZone: string = '',
    public IsArchived: boolean = false,
    public EnableEventWebPage: boolean = false,
    public EnableMessagingfeature: boolean = false,
  ) { }
}

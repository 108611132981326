export class BrowserSettingsInfo {

  public static createEmpty(): BrowserSettingsInfo {
    return new BrowserSettingsInfo('', false);
  }
  constructor(
    public locale: string= '',
    public isFullDayTimeFormat: boolean= false,
  ) {}
}

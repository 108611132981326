/* eslint-disable max-len */
import { HubConnectionBuilder, HubConnectionState, HttpTransportType } from '@microsoft/signalr';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import Common from '@/helper/Common';
import { app, Root, t } from '@/main';
import { createApp } from 'vue';
const timeInterval = 1000;
const hubConnectionUrl = 'hubs/Notification';
let startedPromise = null;
export function install(): void {
  const questionHub = createApp({});
  app.config.globalProperties.$questionHub = questionHub;
  app.config.globalProperties.startSignalR = (rootObj: any) => {
    const connection = new HubConnectionBuilder().withUrl(app.config.globalProperties.$SignalRBaseURL + hubConnectionUrl, { transport: HttpTransportType.WebSockets}).build();
    if (connection.state !== HubConnectionState.Connected) {

      setTimeout(() => start(rootObj).then(() => GetRequestInfoObject(rootObj, 'JoinNotificationApplicationAndEventGroup')), timeInterval);
    }
    connection.onclose(() => {
      start(rootObj);
    });
    // #region Information
    connection.on('ActionUpdatingCustomCategoryDelete', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-customCategory-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('customCategory-item-bulk-delete-operation', notificationItem);
        Root.emit('update-information-linking-in-programs', notificationItem);
      }
    });
    connection.on('ActionUpdatingSingleCustomCategory', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-customCategory-item-notification-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-information-linking-in-programs', notificationItem);
      }
    });
    connection.on('ActionUpdatingSingleFolder', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-folders-item-notification-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-information-linking-in-programs', notificationItem);
      }
    });
    connection.on('ActionUpdatingFolders', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-customCategory-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-information-linking-in-programs', notificationItem);
      }
    });
    connection.on('ActionUpdatingCustomCategoryImages', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-customCategory-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('customCategory-item-bulk-imageUrl-operation', notificationItem);
        Root.emit('update-information-linking-in-programs', notificationItem);
      }
    });
    connection.on('ActionUpdatingLinkedProgram', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-customCategory-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-information-linking-in-programs', notificationItem);
      }
    });
    // #endregion
    // #region Program
    connection.on('ActionUpdatingProgramImages', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-program-items-completed', notificationItem);
      // rootObj.$root.$Root.emit('parent-showhide-parent-loader', true);
      if (notificationItem.IsSuccess) {
        Root.emit('program-item-bulk-imageUrl-operation', notificationItem);
        Root.emit('update-programs-linking-in-information', notificationItem);
      }
    });
    connection.on('ActionUpdatingDateTime', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-program-items-completed', notificationItem);
    });
    connection.on('ActionUpdatingLinkedInformation', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-program-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-programs-linking-in-information', notificationItem);
      }
    });
    connection.on('ActionUpdatingTracks', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-program-items-completed', notificationItem);
    });
    connection.on('ActionUpdatingTags', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-program-items-completed', notificationItem);
    });
    connection.on('ActionUpdatingProgramDelets', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-program-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('program-item-bulk-delete-operation', notificationItem);
        Root.emit('update-programs-linking-in-information', notificationItem);
      }
    });
    connection.on('ActionUpdatingSingleProgram', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-program-item-notification-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-programs-linking-in-information', notificationItem);
      }
    });
    connection.on('ActionOnVideoOnDemandOperation', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-program-video-upload-progress', notificationItem);
    });
    connection.on('ActionOnVideoOnDemandOperationCustomCategory', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-CustomCategory-video-upload-progress', notificationItem);
    });
    // #endregion
    // #region Session Questions
    connection.on('ActionUpdatingSingleSessionQuestion', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-sessionquestions-item-notification-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-attached-program-items-with-session-questions', notificationItem);
      }

    });
    connection.on('ActionSessionsQuestionsDeleted', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-session-questions-deleted', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('program-sessionquestions-bulk-item-deleted', notificationItem.SelectedIds);
        Root.emit('update-attached-program-items-with-session-questions', notificationItem);
      }
    });
    connection.on('ActionSessionQuestionAssignSelectedProgramIds', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-session-questions-assignSelectedIds', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-attached-program-items-with-session-questions', notificationItem);
      }
    });
    // #endregion

    // #region Evaluation Questions
    connection.on('ActionUpdatingSingleEvaluationQuestion', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-evaluationquestions-item-notification-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-attached-program-items-with-evaluation-questions', notificationItem);
      }
    });
    connection.on('ActionEvaluationQuestionsDeleted', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-evaluation-questions-deleted', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('program-evaluationquestions-bulk-item-deleted', notificationItem.SelectedIds);
        Root.emit('update-attached-program-items-with-evaluation-questions', notificationItem);
      }
    });
    connection.on('ActionEvaluationQuestionToggleIsAnonymous', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-evaluation-questions-toggleAnonymous', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-attached-program-items-with-evaluation-questions', notificationItem);
      }
    });
    connection.on('ActionEvaluationQuestionAssignSelectedProgramIds', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-evaluation-questions-assignSelectedIds', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('update-attached-program-items-with-evaluation-questions', notificationItem);
      }
    });
    // #endregion

    // #region Tracks & Tags
    connection.on('ActionUpdatingSingleTrack', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-tracks-item-notification-completed', notificationItem);
    });
    connection.on('ActionUpdatingSingleTag', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('selected-tags-item-notification-completed', notificationItem);
    });

    // #endregion

    // #region program import and update
    connection.on('ActionProgramImport', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      if(notificationItem.ErrorMessage!== null && notificationItem.ErrorMessage!== '') {
        notificationItem.ErrorMessage = t('Notification.ErrorMessage',{error: notificationItem.ErrorMessage});
      }
      Root.emit('batch-selected-program-items-completed', notificationItem);
    });
    // #endregion


    // #region Dialogues
    connection.on('ActionDeleteNotifications', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-notification-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('notification-item-bulk-delete-operation', notificationItem);
      }
    });
    connection.on('ActionDeleteAllResponseInNotifications', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-notification-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('notification-item-bulk-clear-responses-operation', notificationItem);
      }
    });
    connection.on('ActionUpdateNotificationGroupFolder', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      Root.emit('batch-selected-notification-items-completed', notificationItem);
      if (notificationItem.IsSuccess) {
        Root.emit('notification-item-bulk-folder-change-operation', notificationItem);
      }
    });
    connection.on('ActionUpdatingSingleDialogue', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      if (notificationItem.IsSuccess) {
        Root.emit('selected-notification-item-notification-completed', notificationItem);
      }
    });
    connection.on('ActionUpdatingSingleSurvey', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      if (notificationItem.IsSuccess) {
        Root.emit('selected-notification-item-survey-completed', notificationItem);
      }
    });
    connection.on('ActionUpdatingSingleNotificationFolder', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      if (notificationItem.IsSuccess) {
        Root.emit('selected-notification-folders-item-notification-completed', notificationItem);
      }
    });
    // #endregion
    // #region Group selection questions
    connection.on('ActionUpdatingSingleGroupSelectionQuestion', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      if (notificationItem.IsSuccess) {
        Root.emit('selected-group-selection-item-notification-completed', notificationItem);
      }

    });
    connection.on('ActionGroupSelectionQuestionDeleted', (signrResponse: string) => {
      const notificationItem: SignalRModelRequest = JSON.parse(signrResponse);
      if (notificationItem.IsSuccess) {
        Root.emit('group-selection-item-deleted', notificationItem.SelectedIds);
        Root.emit('selected-group-selection-item-notification-completed', notificationItem);
      }
    });
    // #endRegion
    // eslint-disable-next-line @typescript-eslint/no-shadow
    function GetRequestInfoObject(rootObj: any, signalRFunctionName: string) {
      const storeHelper: StoreHelper = new StoreHelper(rootObj.$store);
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      connection.invoke(signalRFunctionName, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId);
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    function start(rootObj: any): any {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      startedPromise = connection.start().catch((err) => {
        try {
          return new Promise((resolve, reject) =>
            setTimeout(() => start(rootObj).then(resolve).catch(reject), timeInterval));
        } catch {
          // Do nothing
        }
      }).then(() => GetRequestInfoObject(rootObj, 'JoinNotificationApplicationAndEventGroup')).then(function() {
        Common.LogToConsole('SignalR Connected!');
      });
      return startedPromise;
    }
  };
}

const SubModuleEnum = {
  Delete: 1,
  EditIconsAndImages: 2,
  EditDateAndTime: 3,
  EditLocation: 4,
  EditTags: 5,
  AssignToTrack: 6,
  LinkInformation: 7,
  SingleCreate: 8,
  SingleEdit: 9,
  SingleDelete: 10,
  MakeAnonymous: 11,
  MakeNotAnonymous: 12,
  EditSelectedProgramIds: 13,
  EditGlobalEvaluationQuestion: 14,
  SingleDetailEdit: 15,
  Sorting: 16,
  LinkProgram: 17,
  AssignToFolder: 18,
  DialogueDeleteAllResponse: 19,
  MuxVodUpdate: 20,
  DialogueExportSelected: 22,
  MuxVodDelete: 21,
  ExportPrograms:22,
  ImportPrograms: 23,
  UpdatePrograms: 24,
  GroupSelection: 25,
};

export default Object.freeze(SubModuleEnum);

export class SubModuleEnumParse {
  public static GetSubModuleEnum(name: string): number {
    let value = 0;
    switch (name) {
    case 'Delete':
      value =  SubModuleEnum.Delete;
    case 'EditIconsAndImages':
      value = SubModuleEnum.EditIconsAndImages;
    case 'EditIconsAndImages':
      value = SubModuleEnum.EditIconsAndImages;
    case 'EditDateAndTime':
      value = SubModuleEnum.EditDateAndTime;
    case 'EditLocation':
      value = SubModuleEnum.EditLocation;
    case 'EditTags':
      value = SubModuleEnum.EditTags;
    case 'AssignToTrack':
      value = SubModuleEnum.AssignToTrack;
    case 'LinkInformation':
      value = SubModuleEnum.LinkInformation;
    case 'SingleCreate':
      value = SubModuleEnum.SingleCreate;
    case 'SingleEdit':
      value = SubModuleEnum.SingleEdit;
    case 'SingleDelete':
      value = SubModuleEnum.SingleDelete;
    case 'MakeAnonymous':
      value = SubModuleEnum.MakeAnonymous;
    case 'MakeNotAnonymous':
      value = SubModuleEnum.MakeNotAnonymous;
    case 'EditSelectedProgramIds':
      value = SubModuleEnum.EditSelectedProgramIds;
    case 'SingleDetailEdit':
      value = SubModuleEnum.SingleDetailEdit;
    case 'Sorting':
      value = SubModuleEnum.Sorting;
    case 'LinkProgram':
      value = SubModuleEnum.LinkProgram;
    case 'AssignToFolder':
      value = SubModuleEnum.AssignToFolder;
    case 'DialogueDeleteAllResponse':
      value = SubModuleEnum.DialogueDeleteAllResponse;
    case 'MuxVodUpdate':
      value = SubModuleEnum.MuxVodUpdate;
    case 'DialogueExportSelected':
      value = SubModuleEnum.DialogueExportSelected;
    case 'MuxVodDelete':
      value = SubModuleEnum.MuxVodDelete;
    case 'ExportPrograms':
      value = SubModuleEnum.ExportPrograms;
    case 'ImportPrograms':
      value = SubModuleEnum.ImportPrograms;
    case 'UpdatePrograms':
      value = SubModuleEnum.ImportPrograms;
    }
    return value;
  }
}

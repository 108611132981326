const ModuleEnum = {
  Program: 1,
  Information: 2,
  ProgramEvaluationQuestions: 3,
  ProgramSessionQuestions: 4,
  Track: 5,
  Tag: 6,
  InformationFolder: 7,
  Dialogue: 8,
  DialogueFolder: 9,
  Survey: 10,
  ParticipantsGroupSelections: 11,
};

export default Object.freeze(ModuleEnum);

export class ModuleEnumParse {
  public static GetModuleEnum(name: string): number {
    let value = 0;
    switch (name) {
    case 'Program':
      value =  ModuleEnum.Program;
    case 'Information':
      value = ModuleEnum.Information;
    case 'InformationFolder':
      value = ModuleEnum.InformationFolder;
    case 'ProgramEvaluationQuestions':
      value = ModuleEnum.ProgramEvaluationQuestions;
    case 'ProgramSessionQuestions':
      value = ModuleEnum.ProgramSessionQuestions;
    case 'Track':
      value = ModuleEnum.Track;
    case 'Tag':
      value = ModuleEnum.Tag;
    case 'Dialogue':
      value = ModuleEnum.Dialogue;
    case 'DialogueFolder':
      value = ModuleEnum.DialogueFolder;
    }
    return value;
  }
}

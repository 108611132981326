
import { FetchData } from '@/main';
import { Component, Vue, Prop, Watch , toNative } from 'vue-facing-decorator';
@Component({
  components: {
  },
})
class TrialPackageIndicator extends Vue {
  @Prop() private showTrailIndicator?: boolean;
  @Prop() private packageExpirationPendingDays?: number;
  @Prop() private applicationId?: number;
  @Prop() private isApplicationOnNewPackage?: boolean;
  private successMessage = '';
  private localShowModalPopup = false;
  private pricingMessage = '';
  private pricingMessageEnterprise = '';
  private eventsvalue = 'Up to 5 events / year';
  private eventsDataLabels: string[] = [
    'Up to 2 events / year',
    'Up to 5 events / year',
    'Up to 10 events / year',
    'Up to 20 events / year',
    'More than 20',
  ];
  private isInteretedInOwnApp = false;
  private isCheckboxDisabled = false;
  private isInterestedinRegistrationSystem = false;
  private isRegistrationCheckboxDisabled = false;
  private isContactButtonDisabled = false;
  private participantsDataLabels: string[] = [];
  private pricingUrl = 'https://ventla.io/pricing/';
  private selectedParticipantValue = '2500';
  private Cancel() {
    this.localShowModalPopup = false;
  }
  private OpenPopup() {
    this.GetNewPricingPageUrl();
  }
  private EventsChanged() {
    this.RequestPackagePricing();
  }
  private RequestPackagePricing() {
    if (
      this.eventsvalue === 'More than 20'
    ) {
      this.pricingMessage = this.$t('TrialIndicator.CustomPricing').toString();
    } else {
      const selectedEvents = this.GetSelectedEvents();
      FetchData('/TrialLicense/GetNewPackage', JSON.stringify({
        events: selectedEvents,
        participants: this.selectedParticipantValue,
        appid: this.applicationId!,
        isEnterprise: this.isInteretedInOwnApp,
        isRegistrationManagerRequired: this.isInterestedinRegistrationSystem,
      }))
        .then((response) => {
          if (selectedEvents === 1) {

            this.pricingMessage = this.$t('TrialIndicator.SingleEventPricing', {
              price: response.message,
            }).toString();
          } else {
            this.pricingMessage = this.$t('TrialIndicator.Pricing', {
              price: response.message,
            }).toString();
          }

          if (response.message2 !== '') {

            this.pricingMessageEnterprise = this.$t(
              'TrialIndicator.OneTimeSetupPricing',
              { price: response.message2 },
            ).toString();
          } else {
            this.pricingMessageEnterprise = '';
          }
        })
        .catch(() => {
          // Log error if required
        });
    }
  }
  private GetNewPricingPageUrl() {
    FetchData('/TrialLicense/GetNewPricingPageUrl', JSON.stringify({
      appid: JSON.stringify(this.applicationId!),
    }))
      .then((response) => {
        const newUrl = response.url;
        this.pricingUrl = newUrl;
        this.localShowModalPopup = true;
        this.isContactButtonDisabled = false;
      })
      .catch(() => {
        // Log error if required
      });
  }
  private GetSelectedEvents() {
    return this.eventsvalue.match(/\d+/g)!.map(Number)[0];
  }

  private ContactMeForQuote() {
    this.SubmitUpgradePackageRequest();
  }

  private ContactMeDifferentNeeds() {
    this.SubmitUpgradePackageRequest();
  }

  private SubmitUpgradePackageRequest() {
    this.isContactButtonDisabled = true;

    const requestedParticipants = this.selectedParticipantValue;
    const requestedEvents = this.eventsvalue;
    let isCustom = false;
    if (
      this.eventsvalue === 'More than 20'
    ) {
      isCustom = true;
    }
    const objForJson = {
      NumberOfEvents: requestedEvents,
      NumberOfParticipants: requestedParticipants,
      IsInterestedInOwnApp: this.isInteretedInOwnApp,
      IsInterestedInRegistrationSystem: this.isInterestedinRegistrationSystem,
      IsCustom: isCustom,
    };
    const jsonData = JSON.stringify(objForJson);

    FetchData('/TrialLicense/SubmitUpgradePackageRequest', JSON.stringify({
      packageUpgradeRequestJson: jsonData,
    }))
      .then((response) => {
        if (response.status === 'success') {
          this.successMessage = this.$t(
            'TrialIndicator.SuccessMessage',
          ).toString();
        } else {
          alert('Error during submitting you request.');
          this.successMessage = '';
          this.isContactButtonDisabled = false;
        }
      })
      .catch(() => {
        // Log error if required
      });
  }
  private SubmitNewTrialUpgradePackageRequest() {
    this.isContactButtonDisabled = true;
    const objForJson = {
      IsCustom: true,
    };
    const jsonData = JSON.stringify(objForJson);
    FetchData('/TrialLicense/SubmitUpgradePackageRequest', JSON.stringify({
      packageUpgradeRequestJson: jsonData,
    }))
      .then((response) => {
        if (response.status === 'success') {
          this.successMessage = this.$t(
            'TrialIndicator.SuccessMessage',
          ).toString();
        } else {
          alert('Error during submitting you request.');
          this.successMessage = '';
          this.isContactButtonDisabled = false;
        }
      })
      .catch(() => {
        // Log error if required
      });
  }

  @Watch('isInteretedInOwnApp')
  private onPropertyChanged() {
    this.RequestPackagePricing();
  }

  @Watch('isInterestedinRegistrationSystem')
  private onRegistrationSystemPropertyChanged() {
    this.RequestPackagePricing();
  }
}
export default toNative(TrialPackageIndicator);

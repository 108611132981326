import { FetchData } from '@/main';
import { Vue } from 'vue-facing-decorator';

/* eslint-disable @typescript-eslint/no-unsafe-argument */
export default class Common extends Vue{
  public static LogToConsole(message: string) {
    // eslint-disable-next-line no-console
    console.log(message);
  }
  public CompareObjects(obj1: any, obj2: any) {
    // Loop through properties in object 1
    for (const p in obj1) {
      if (obj1.hasOwnProperty(p) && obj2) {
        // Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
          return false;
        }
        if (Object.keys(obj1).length !== Object.keys(obj2).length) {
          return false;
        }

        switch (typeof obj1[p]) {
        // Deep compare objects
        case 'object':
          if (obj1[p] instanceof Date) {
            if (new Date(obj1[p]).getTime() !== new Date(obj2[p]).getTime()) {
              return false;
            }
            break;
          }
          if (!this.CompareObjects(obj1[p], obj2[p])) {
            return false;
          }
          break;
          // Compare function code
        case 'function':
          if (
            typeof obj2[p] === 'undefined' ||
                            (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())
          ) {
            return false;
          }
          break;
          // Compare string code
        case 'string':
          if (obj1[p] !== null && obj2[p] !== null && obj1[p].toString().trim() !== obj2[p].toString().trim()) {
            return false;
          }
          break;
          // Compare values
        default:
          if (obj1[p] !== obj2[p]) {
            return false;
          }
        }
      }
    }

    // Check object 2 for any extra properties
    for (const p2 in obj2) {
      if (obj1 && (typeof (obj1[p2]) === 'undefined') && (typeof (obj2[p2]) !== 'undefined')) {
        return false;
      }
    }

    return true;

  }
  public GetHours(): number[] {
    const hoursToShow = 8;
    const hours: number[] = this.GetNumberListing(hoursToShow);
    return hours;
  }
  public GetMinutes(): number[] {
    const minutesToShow = 59;
    const minutes: number[] = this.GetNumberListing(minutesToShow);
    return minutes;
  }
  public GetNumberListing(size: number): number[] {
    const values: number[] = [];
    for (let index = 0; index < size + 1; index++) {
      values.push(index);
    }
    return values;
  }
  public AddParticipansLink(path: string, customCategoryIds: number[], participantIds: number[]){
    FetchData(path, JSON.stringify({
      customCategoryIds,
      participantIds,
    }))
      .then((response) => {
        if (response.success === false) {
          return false;
        } else {
          return true;
        }
      })
      .catch(() =>false,
      );

  }
  public RemoveParticipansLink(path: string, customCategoryIds: number[], participantIds: number[]) {
    FetchData(path, JSON.stringify({
      customCategoryIds,
      participantIds,
    }))
      .then((response) => {
        if (response.success === false) {
          return false;
        } else {
          return true;
        }
      })
      .catch(() =>
        false,
      );
  }
  public async VerifyThresholdLimit(): Promise<any> {
    return await FetchData('/Application/VerifyThresholdLimit','')
      .then((response: any) => {
        if (response) {
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        // Log error if required
      });
  }

  public static GetTimeZoneName(name: string) {
    const result = oldInatimeZones.filter(function(obj) {
      return obj.oldName === name;
    });
    if (result != null && result.length > 0) {
      return result[0].newName;
    }
    return name;
  }
  public static copyPublicLinkToClipboard(link: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    let result = false;
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        result = true;
      } else {
        result = false;
        alert('Oops, unable to copy');
      }
    } catch (err) {
      alert('Oops, unable to copy');
    }
    document.body.removeChild(selBox);
    return result;
  }
  // Generate GUID
  public static GenerateGuid() {
    const guidFormatter = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return guidFormatter.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  // To scroll back to top of page
  public static ScrollToTop(id: string) {
    setTimeout(() => {
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      }
    }, 200);
  }
  // To Trim space in between string seperated by semicolon
  public static TrimSpaceInBetween(item: string): string {
    if(item!== null && item!== undefined){
      let convertedItem = item.split(';');
      convertedItem = convertedItem.map((val: string)=> val.trim());
      return convertedItem.join(';');
    }
    return item;
  }
  // Check the color is light color or dark color
  public static LightOrDark(color: any) {
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      // eslint-disable-next-line no-var
      var r = color[1];
      // eslint-disable-next-line no-var
      var g = color[2];
      // eslint-disable-next-line no-var
      var b = color[3];
    } else {
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return true;
    } else {
      return false;
    }
  }
}
const oldInatimeZones = [
  { oldName: 'Asia/Calcutta', newName: 'Asia/Kolkata' },
];
